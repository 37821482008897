@use "src/styles/typography";
@use "src/styles/palette";
@use "src/styles/layout";

.expert {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin: 1.5rem 0;

  @media (min-width: layout.breakpoint(md)) {
    transform: rotate(-8deg);
  }

  .wrapper {
    position: relative;

    .image_wrapper {
      width: 300px;
      height: 350px;
      overflow: hidden;
      border-radius: 0.5rem;

      & > * {
        transition: transform 0.4s;
      }
    }

    .label {
      position: absolute;
      padding: 0.625rem;
      border-radius: 0 0.25rem;
      left: 1rem;
      right: 1rem;
      bottom: 1.25rem;
      overflow: hidden;
      background-color: palette.$white;

      .title {
        @extend .header_5;
        overflow: hidden;
      }

      .description {
        @extend .tag_text;
        font-weight: 100;
      }

      .description {
        text-wrap: balance;
      }

      .button {
        position: absolute;
        font-weight: 100;
        margin-top: 0.5rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .wrapper:hover {
    .image_wrapper > * {
      transform: scale(1.05);
    }

    .title {
      text-decoration: underline;
    }
  }
}
